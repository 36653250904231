import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";

const ConfirmSendInvitation = ({
  open,
  handleDialogClose = null,
  sendMutation,
  data,
}) => {
  const [deleteContent, setDeleteContent] = useState({
    header: "Create Scenario?",
    body: "You must create scenario before inviting your member",
  });

  const { mutateAsync, isLoading } = sendMutation;

  const handleCloseModal = (e) => {
    e.stopPropagation();
    handleDialogClose(false);
  };

  const handleSend = async () => {
    try {
      await mutateAsync(data);
      setDeleteContent({
        header: "Success!",
        body: "Your scenario was successfully created.",
      });
      handleDialogClose(false);
    } catch (error) {
      setDeleteContent({
        header: "Error!",
        body: error?.data?.message,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      className="custom-modal-container"
      style={{ zIndex: 999 }}
    >
      <div
        role="presentation"
        className="custom-modal-body"
        onClick={(e) => e.stopPropagation()}
        style={{ width: 600 }}
      >
        <div className="delete-property-context">
          <div className="delete-property-header">{deleteContent.header}</div>
          <div className="delete-property-body">{deleteContent.body}</div>
        </div>
      </div>

      <div className="custom-modal-button-group">
        <button
          type="button"
          className="btn"
          onClick={handleCloseModal}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn delete-property"
          onClick={handleSend}
        >
          {isLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Create scenario & Send invitation"
          )}
        </button>
      </div>
    </Dialog>
  );
};

ConfirmSendInvitation.propTypes = {
  open: PropTypes.bool,
  handleDialogClose: PropTypes.func,
};

export default ConfirmSendInvitation;
